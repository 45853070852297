<script setup>
import { computed } from "vue";

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    button_type: {
        type: String,
        default: "",
    },
});

const classes = computed(() => {
    if (props.button_type === "danger") {
        return "bg-red-700 text-white font-russo hover:bg-red-800 text-sm px-5 py-2.5 disabled:bg-gray-400 transition ease-in-out duration-150";
    }
    else if (props.button_type === "success") {
        return "bg-green-500 text-white font-russo hover:bg-green-700 text-sm px-5 py-2.5 disabled:bg-gray-400 transition ease-in-out duration-150";
    }
    else if (props.button_type === "warning") {
        return "bg-yellow-400 text-white font-russo hover:bg-yellow-500 text-sm px-5 py-2.5 disabled:bg-gray-400 transition ease-in-out duration-150";
    }
    else if (props.button_type === "info") {
        return "bg-blue-500 text-white font-russo hover:bg-blue-700 text-sm px-5 py-2.5 disabled:bg-gray-400 transition ease-in-out duration-150";
    }
    else if (props.button_type === "white") {
        return "bg-white text-gray-500 font-russo hover:bg-slate-300 text-sm px-5 py-2.5 disabled:bg-gray-400 transition ease-in-out duration-150";
    }
    else {
        return "bg-primary text-white font-russo hover:bg-indigo-700 text-sm px-5 py-2.5 disabled:bg-gray-400 transition ease-in-out duration-150";
    }
});
</script>

<template>
    <button :type="type" :class="classes">
        <slot />
    </button>
</template>
